import { isValidPhoneNumber } from "react-phone-number-input";
import { z } from "zod";

const contactFormSchema = z.object({
  user_email: z.string().min(2).max(113).email(),
  user_phone: z
    .string()
    .refine(isValidPhoneNumber, { message: "Invalid phone number" })
    .or(z.literal(""))
    .optional(),
  body_title: z.string().min(8),
  body_content: z.string().min(8),
});

export default contactFormSchema;
