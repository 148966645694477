import React from "react";
import { SelectedItem } from "@/app/(slideshow)/build/store/build-store";
import Image from "next/image";
import PlayTechLogo from "@/public/playtech-colour.png";
import { Separator } from "@/components/ui/separator";
import { ProductAdditionalTypes } from "@/types/products-types";
import { Json } from "@/lib/database.types";
import { CartItemTypes } from "@/types/additional-types";

const CartPDF = ({
  build,
  cartItems,
  productArr,
  componentRef,
}: {
  build: SelectedItem[] | null;
  productArr: {
    additional_data: Json;
    id: string;
  }[];
  cartItems: CartItemTypes;
  componentRef: React.MutableRefObject<null>;
}) => {
  let totalPrice = 0;
  let totalCount = 0;

  if (build) {
    build.forEach((hardware) => {
      const cartItem = cartItems.find((item) => item.id === hardware.id);
      if (hardware?.stock?.price && cartItem) {
        const amount = cartItem.amount || 1;
        totalPrice += hardware.stock.price * amount;
        totalCount += amount;
      }
    });
  }
  return (
    <div className="invisible fixed inset-0 -z-50 w-full">
      <div
        ref={componentRef}
        className="flex h-full w-full flex-col items-center justify-start bg-white px-10 py-8 uppercase text-black"
      >
        <div className="flex h-full w-full flex-col items-start justify-start rounded-md border px-8 py-6 shadow-sm">
          <div
            id="header"
            className="flex flex-row items-center justify-start gap-6 pb-4"
          >
            <Image
              height={48}
              width={48}
              src={PlayTechLogo}
              alt="playtech-logo"
            />
            <div className="flex h-full flex-col items-start justify-start leading-snug">
              <span className="font-medium">Play Tech Computer Solutions</span>
              <span className="text-sm">
                Sripada Main Street, Malwala, Rathnapura.
              </span>
              <span className="text-sm opacity-70">076 996 5262</span>
            </div>
          </div>
          <Separator />
          <span className="text-base font-semibold text-primary">Cart PDF</span>
          <div id="products" className="mt-8 w-full">
            <div className="grid grid-cols-[2fr,0.8fr,0.6fr,1fr] grid-rows-1 place-items-start bg-slate-100 px-2">
              <div className="whitespace-nowrap rounded-md py-2 text-sm font-semibold">
                Name
              </div>
              <div className="w-full whitespace-nowrap rounded-md py-2 text-center text-sm font-semibold">
                Warranty
              </div>
              <div className="w-full whitespace-nowrap rounded-md py-2 text-center text-sm font-semibold">
                Qty
              </div>
              <div className="w-full whitespace-nowrap rounded-md py-2 text-right text-sm font-semibold">
                Price
              </div>
            </div>
            {build?.map((hardware, index) => {
              const cartItem = cartItems.find(
                (item) => item.id === hardware.id,
              );
              const amount = cartItem?.amount || 1;
              return (
                <div
                  className="grid grid-cols-[2fr,0.8fr,0.6fr,1fr] grid-rows-1 place-items-start border-b px-2 text-xs"
                  key={hardware?.id + index}
                >
                  <div className="ml-0 rounded-md bg-white/80 p-1">
                    {hardware?.name}
                  </div>
                  <div className="ml-0 w-full rounded-md bg-white/80 p-1 text-center uppercase">
                    {(
                      productArr?.find((p) => p.id === hardware.id)
                        ?.additional_data as ProductAdditionalTypes
                    )?.find((v) => v.type === "warranty")?.value ?? "N/A"}{" "}
                  </div>
                  <div className="ml-0 w-full rounded-md bg-white/80 p-1 text-center">
                    {amount}
                  </div>
                  {hardware?.stock && (
                    <div className="ml-0 flex w-full flex-row items-center justify-end gap-3 rounded-md bg-white/80 p-1 text-right text-sm">
                      {hardware.stock.price * amount}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="mt-6 flex w-full flex-col items-start justify-center">
            <p className="items-start text-xs font-bold">
              Total Price: {totalPrice}LKR
            </p>
            <p className="items-start text-xs font-bold ">
              Total Product Count: {totalCount}
            </p>
            <div className="flex h-auto w-full flex-col items-center justify-center px-4 pt-4">
              <p className="w-auto text-pretty pt-2 text-center text-xs font-bold opacity-60">
                Please note that this is only a Digitally Generated Quotation
                and not an Invoice and these prices are valid for 7 days only.
              </p>
              <p className="pt-2 text-center text-xs font-semibold  text-primary">
                Thank You!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPDF;
