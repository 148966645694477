import { contactFormFiledTypes } from "@/types/contact-types";

const contactFormFields: contactFormFiledTypes = [
  {
    name: "user_email",
    label: "Email",
    description: "This is your email address.",
  },
  {
    name: "user_phone",
    label: "Phone Number ( Optional )",
    description: "This is your phone number",
  },
  {
    name: "body_title",
    label: "Title",
    description: "This is your title",
  },
  {
    name: "body_content",
    label: "Message Content",
    description: "This is your Message",
  },
];

export default contactFormFields;
