"use client";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { ReactNode } from "react";

const NavItem = ({
  icon,
  tooltip,
  id,
  disabled,
}: {
  icon: ReactNode;
  tooltip: string;
  id: string;
  disabled?: boolean;
}) => {
  const pathName = usePathname();
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        {!disabled ? (
          <Link
            href={id}
            className={cn(
              "flex h-9 w-9 cursor-pointer items-center justify-center whitespace-nowrap rounded-lg border border-none border-input bg-background/60 fill-foreground p-1.5 text-sm font-medium text-muted-foreground shadow-sm transition-colors hover:bg-background/90 hover:text-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 md:h-8 md:w-8",
              (pathName === id || (id !== "/" && pathName.startsWith(id))) &&
                "bg-secondary-foreground/80 text-secondary hover:bg-secondary-foreground hover:text-secondary/80",
            )}
          >
            {icon}
            <span className="sr-only">{tooltip}</span>
          </Link>
        ) : (
          <div className="flex h-9 w-9 cursor-pointer items-center justify-center whitespace-nowrap rounded-lg border border-none border-input bg-background/60 fill-foreground p-1.5 text-sm font-medium text-muted-foreground shadow-sm transition-colors hover:bg-background/90 hover:text-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 md:h-8 md:w-8">
            {icon}
            <span className="sr-only">{tooltip}</span>
          </div>
        )}
      </TooltipTrigger>
      <TooltipContent side="right">{tooltip}</TooltipContent>
    </Tooltip>
  );
};

const SmNavItem = ({
  icon,
  tooltip,
  disabled,
  id,
}: {
  icon: ReactNode;
  tooltip: string;
  disabled?: boolean;
  id: string;
}) => {
  const pathName = usePathname();

  return disabled ? (
    <div className="flex h-10 w-full cursor-pointer flex-row items-center justify-start gap-5 whitespace-nowrap rounded-lg border border-none border-input bg-background/60 fill-foreground px-4 py-2 text-sm font-medium text-muted-foreground shadow-sm transition-colors hover:bg-background/90 hover:text-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
      {icon}
      <span>{tooltip}</span>
    </div>
  ) : (
    <Link
      href={id}
      className={cn(
        "flex h-10 w-full cursor-pointer flex-row items-center justify-start gap-5 whitespace-nowrap rounded-lg border border-none border-input bg-background/60 fill-foreground px-4 py-2 text-sm font-medium text-muted-foreground shadow-sm transition-colors hover:bg-background/90 hover:text-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
        (pathName === id || (id !== "/" && pathName.startsWith(id))) &&
          "bg-secondary-foreground text-secondary hover:bg-secondary",
      )}
    >
      {icon}
      <span>{tooltip}</span>
    </Link>
  );
};

export { SmNavItem, NavItem };
