"use client";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import React, { useState } from "react";
import { BiLoader, BiMessage } from "react-icons/bi";
import { useToast } from "@/components/ui/use-toast";
import { useRouter } from "next/navigation";
import contactFormSchema from "./contact-form-schema";
import contactFormFields from "./contact-form-fileds";
import { Textarea } from "../ui/textarea";
import supabaseClient from "@/app/supabase/supabase-client";
import Link from "next/link";
import { PhoneInput as ReactPhoneInput } from "../ui/phone-input";
import { User } from "@supabase/supabase-js";

const ContactForm = ({
  userSession,
}: {
  userSession:
    | {
        user: User;
      }
    | {
        user: null;
      };
}) => {
  const { supabase } = supabaseClient();
  const { toast } = useToast();
  const router = useRouter();
  const [formLoading, setFormLoading] = useState(false);

  const contactForm = useForm<z.infer<typeof contactFormSchema>>({
    resolver: zodResolver(contactFormSchema),
    defaultValues: {
      user_email: "",
      body_content:
        "Im ABC and I want A PC For A Budget Around 000 RS And I would Like You To Contact Me , Thanks",
      body_title: "",
      user_phone: "",
    },
  });

  const PhoneInputWrapper = React.forwardRef<HTMLInputElement, any>(
    (props, ref) => {
      return <ReactPhoneInput {...props} ref={ref} />;
    },
  );

  PhoneInputWrapper.displayName = "PhoneInputWrapper";
  async function onSubmit(values: z.infer<typeof contactFormSchema>) {
    setFormLoading(true);
    const { data, error } = await supabase.from("messages").insert({
      message_content: values.body_content,
      message_title: values.body_title,
      user_email: values.user_email,
      user_id: await userSession?.user?.id,
      user_phone: values.user_phone!,
    });
    if (!error) {
      toast({
        title: "User Message",
        description: "Message Sent Successfully",
      });
      router.refresh();
    } else {
      toast({
        title: "User Message",
        description: "Message Sending was A Unsuccess",
      });
      console.log("Mesage Error :");
      console.log(error);
      router.refresh();
    }
    setFormLoading(false);
  }
  return (
    <div className="flex h-full w-full flex-col items-center justify-start border bg-background/70 p-6 font-poppins font-light">
      <span className="w-full text-left text-2xl max-sm:text-xl">
        Send Us A Message
      </span>
      <span className="w-full text-left text-base max-sm:text-sm">
        Message Us Your Concern, We'll Help ASAP
      </span>
      <Form {...contactForm}>
        <form
          onSubmit={contactForm.handleSubmit(onSubmit)}
          className="flex w-full flex-col items-start justify-center space-y-9 pt-6"
        >
          {contactFormFields.map((f, index) => (
            <FormField
              key={index}
              control={contactForm.control}
              name={f.name}
              render={({ field }) => (
                <FormItem className="flex h-full w-full flex-col items-start justify-center max-sm:text-sm">
                  <FormLabel className="w-full text-left">{f.label}</FormLabel>
                  <FormControl>
                    {f.name === "body_content" ? (
                      <Textarea {...field} className="min-h-52" />
                    ) : f.name === "user_phone" ? (
                      <PhoneInputWrapper
                        name={field.name}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        value={field.value as any}
                        placeholder="Enter a phone number"
                        className="w-full"
                      />
                    ) : (
                      <Input
                        type={f.name === "user_email" ? "email" : "text"}
                        {...field}
                      />
                    )}
                  </FormControl>
                  <FormDescription>{f.description}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          ))}
          <span className="w-full pr-6 pt-2 text-left text-xs opacity-70">
            * On Submitting You Are Going to Agree to out
            <Link href="/terms-conditions" className="mx-1 underline">
              Terms And Condititons
            </Link>
          </span>
          <Button
            disabled={formLoading}
            type="submit"
            variant="outline"
            className="flex h-11 w-full flex-row items-center justify-center gap-4 bg-secondary/40 hover:bg-secondary/60"
          >
            {!formLoading ? (
              <BiMessage />
            ) : (
              <BiLoader className="animate-spin" />
            )}{" "}
            Send Message
          </Button>
        </form>
      </Form>
    </div>
  );
};

export default ContactForm;
